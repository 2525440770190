
import { Client, ClientInvitation, ClientInvitationInfo, ClientInvitationResponse } from "@/types/responses"
import { SnackbarInfo } from "@/types/responses"

const InvitationLinkDialog = () => import("@/components/dialogs/InvitationLinkDialog.vue")
const ShowInvitationDialog = () => import("@/components/dialogs/ShowInvitationDialog.vue")
const ManageInvitationDialog = () => import("@/components/dialogs/ManageInvitationDialog.vue")

import { DataTableHeader } from "vuetify"
import Vue from "vue"
import { Store } from "../../store"
export default Vue.extend({
	name: "ClientInvitations",
	components: {
		InvitationLinkDialog,
		ShowInvitationDialog,
		ManageInvitationDialog
	},
	async mounted() {
		await this.getClientInvitations()
	},
	data(): {
        search: string
		invitationLink: boolean
		showInvitation: boolean
		manageInvitation: boolean
		selectedClientInvitation: null | ClientInvitation
		loading: boolean
		mandatory: boolean
		activeClass: string
		selectedChip: string
        headers: DataTableHeader[]
        timeLeft: number
		snackTimeout: number

		snackBar: boolean
		snackBarText: string

		copiedLinkId: string | undefined
	} {
		return {
            search: "",
			invitationLink: false,
			showInvitation: false,
			manageInvitation: false,
			selectedClientInvitation: null,
			loading: false,
			mandatory: false,
			activeClass: "",
			selectedChip: "",
            headers: [
				{ text: "Name", value: "displayName", sortable: false },
				{ text: "User", value: "invitingUser", sortable: false },
				{ text: "Type", value: "invitationType", sortable: false, filterable: false },
				{ text: "Manual approval", value: "manualApproval", align: 'center', sortable: false, filterable: false },
				{ text: "Registreres som", value: "linkOnboardingKind", align: 'center', sortable: false, filterable: false },
				//{ text: "Active", value: "active", align: 'center', sortable: false, filterable: false },
				{ text: "Registrations", value: "totalRegistered", align: 'center', sortable: false, filterable: false },
				{ text: "Waiting for approval", value: "waitingForApproval", align: 'center', sortable: false, filterable: false },

				{ text: "", value: "action", sortable: false, filterable: false },
			],
            timeLeft: 2,
			snackTimeout: 1,

			snackBar: false,
			snackBarText: "",

			copiedLinkId: ""
		}
	},
	computed: {
		clientInvitations(): ClientInvitationResponse {
			return this.$store.state.context.clientInvitations;
		}
	},
	methods: {
		async copyLink(item: ClientInvitationInfo){
            let snackbarInfo: SnackbarInfo;
            const registrationBaseUrl = (this.$store as Store).state.config.registrationBaseUrl;
            const link = registrationBaseUrl + (registrationBaseUrl.endsWith("/") ? item.linkCode : "/" + item.linkCode);
            try {
                await navigator.clipboard.writeText(link);
                snackbarInfo = {
                    success: true,
                    msg: "Successfully copied link to clipboard!",
                    e: null
                }
                this.copiedLinkId = item.jobId
				console.log(item.jobId)
            } catch(e){
                snackbarInfo = {
                    success: false,
                    msg: "Copy link to clipboard failed.",
                    e: null
                }
                this.copiedLinkId = ""
            }

			this.showSnackbarInfo(false, snackbarInfo, false, 1)
        },
		invitationLinkClick(): void {
            this.openInvitationLinkDialog()
		},
		openInvitationLinkDialog(): void {
			this.invitationLink = true
		},
		resetCopyLink(jobId: string): void {
			this.copiedLinkId = jobId
		},
		async showInvitationClick(item: ClientInvitationInfo): Promise<void> {
			// Double check validity of client invitations
			const hit = this.$store.state.context.clientInvitations.find(
				(clientInvitation: ClientInvitationInfo) => clientInvitation.jobId === item.jobId
			)
			if(hit){
				/* Set selectedClientInvitation */
				const clientInvitation: ClientInvitation = {
					id: hit.jobId,
					name: hit.displayName
				}
				/* Store selection */
            	await this.$vStore.dispatch("context/setClientInvitation", clientInvitation)
				this.selectedClientInvitation = clientInvitation;
            	this.openShowInvitationDialog()
			} else {
				console.warn("Error selecting client invitation.")
			}
		},
		openShowInvitationDialog(): void {
			this.showInvitation = true
		},

		async manageInvitationClick(item: ClientInvitationInfo): Promise<void> {
			// Double check validity of client invitations
			const hit = this.$store.state.context.clientInvitations.find(
				(clientInvitation: ClientInvitationInfo) => clientInvitation.jobId === item.jobId
			)
			if(hit){
				/* Set selectedClientInvitation */
				const clientInvitation: ClientInvitation = {
					id: hit.jobId,
					name: hit.displayName
				}

				/* Store selection */
            	await this.$vStore.dispatch("context/setClientInvitation", clientInvitation)
				this.selectedClientInvitation = clientInvitation;
            	this.openManageInvitationDialog()
			} else {
				console.warn("Error selecting client invitation.")
			}
		},
		openManageInvitationDialog(): void {
			this.manageInvitation = true
		},

        async countDownTimer(text: string, showCountdown: boolean, length: number): Promise<void> {
			this.timeLeft = length
			if(showCountdown) this.snackBarText = text + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
			else this.snackBarText = text

			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if(showCountdown) this.snackBarText = text + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
					else this.snackBarText = text
					
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
		async refreshClientInvitations(): Promise<void> {
			this.loading = true
			try {
                await this.countDownTimer("", true, 1)
				await this.$vStore.dispatch("context/updateClientInvitations")
			} catch (e) {
				console.warn("Could not find any customerClients for this customer", e)
			}
			this.loading = false
		},
		async silentRefresh(): Promise<void> {
			try {
				await this.$vStore.dispatch("context/updateClientInvitations")
			} catch (e) {
				console.warn("silentRefresh failed.", e)
			}
		},
		async silentRefreshClientOrganizations(): Promise<void> {
			if (this.$vStore.state.context.customerClients.length > 0) return // Do not refresh if user never navigated to orgs and projects
			try {
				await this.$vStore.dispatch("context/updateCustomerClients")
			} catch (e) {
				console.warn("Could not find any customerClients for this customer", e)
			}
		},
		async getClientInvitations(): Promise<void> {
			if (this.loading) return
			if (this.$store.state.context.clientInvitations.length > 0) return
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updateClientInvitations")
				this.loading = false
			} catch (e) {
				console.warn("Could not find any clientInvitations for this customer", e)
			}
		},

		async showSnackbarInfo(showLoading: boolean, snackbarInfo: SnackbarInfo, showCountdown: boolean, length: number): Promise<void> {			
			this.snackTimeout = length * 1000
			this.snackBar = true

			if(snackbarInfo.success){
				if(showLoading) this.loading = true
				try{
					await this.countDownTimer(snackbarInfo.msg, showCountdown, length)
				} catch (e){}
				this.loading = false
			} else {
				if(showCountdown) await this.countDownTimer(snackbarInfo.msg + "<br/><br/>Error: " + JSON.stringify(snackbarInfo.e), showCountdown, length)
				else this.snackBarText = snackbarInfo.msg + "<br/><br/>Error: " + JSON.stringify(snackbarInfo.e)
			}
		}
	}
})
